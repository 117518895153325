import 'babel-polyfill'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import Router from './router'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(VueCookies)

Vue.use(VueAxios, axios);

Router.afterEach(() => {
  setTimeout(() => app.loading = false, 500) // timeout for demo purposes
})

const app = new Vue({
  vuetify,
  data: { loading: true },
  router: Router,
  render: h => h(App),
}).$mount('#app')

<template>
    <v-layout row wrap>
        <v-flex xs12 class="layout wrap row flex-center">
            <v-flex xs12 class="offset-md4 text-xs-center layout row align-center justify-center">
                <img :src="imagePath()" class="pa-3" width="350px">
            </v-flex>
            <v-flex xs12 class="layout row align-center justify-center">
                <v-btn depressed class="florence florence-text font-weight-bold sans" @click.stop="signup = true">Accedi / Registrati</v-btn>
                <dialog-form :sign-in.sync="signIn" :sign-up.sync="signUp"></dialog-form>
            </v-flex>
        </v-flex>
        <v-layout xs12 class="row wrap florence-skyline align-center">
        </v-layout>
        <v-layout class="row wrap florence-bg align-content-start pb-5 justify-center">
            <v-container class="layout row wrap">
                <v-flex xs12 class="text-xs-center pb-5 flex-center">
                    <h2 class="sans font-weight-regular florence-text-bg">Il Fintech Digital Index</h2>
                </v-flex>
                <v-flex class="xs12 order-xs2 sm6 layout row align-content-center justify-center box white wrap">
                    <v-flex xs7 class="sans black--text">
                        Un database digitale dinamico che raccoglie dati, informazioni e caratteristiche delle startup fintech italiane
                        <br><br>
                        Per ciascuna impresa fintech è disponibile una scheda analitica comprensiva di informazioni quali dati anagrafici e contabili, traguardi e obiettivi imprenditoriali, clienti di riferimento e servizi offerti.
                        <br><br>
                        Le funzionalità di ricerca intuitive e il collocamento delle imprese all’interno dei rispettivi settori fintech di appartenenza, permettono all’utente di navigare con facilità il mondo della finanza digitale italiana.
                    </v-flex>
                    <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('http://fintech.innexta.it')">Vai al servizio</v-btn></v-flex>
                </v-flex>
                <v-flex class="xs12 order-xs1 sm6 box index layout row align-end">
                    <v-flex class="xs12 text-xs-right">
                        <img src="@/assets/icon-index.png" width="40px" height="35px" class="ma-3">
                    </v-flex>
                </v-flex>
            </v-container>
        </v-layout>
        <v-flex xs12 class="footer row black order-xs7 pa-3 layout text-xs-center justify-center">
            <span class="sans white--text">Powered by Innexta © 2022</span>
        </v-flex>
    </v-layout>
</template>

<script>

import Vue from 'vue'
import DialogForm from '@/components/form'

export default {
  components: {
    DialogForm
  },
  props: {
    title: {
      type: String,
    },
    cciaa: {
      type: String
    },
    logo: {
      type: String
    },
  },
  methods: {
    imagePath() {
      return require(`../assets/cc/${this.logo}`);
    },
    checkAuth(url) {
      if(!Vue.$cookies.get('cccf_auth')){
        this.signIn = true
      } else {
        window.open(url, '_blank');
      }
    }
  },
  data () {
    return {
      signIn: false,
      signUp: false,
    }
  }
}
</script>

<style scoped lang="scss">
    button{
        border-radius: 4px !important;
    }
    a{
        text-decoration: none;
        color: initial;
    }
    .florence {
        background: #2b3876 !important;
    }
    .florence-bg {
        background: #e5e5e5 !important;
        color: #e5e5e5 !important;
    }
    .florence-text {
        background: #fff;
        color: #fff;
    }
    .florence-text-bg {
        color: #2b3876 !important;
    }
    .signup{
        width: 200px;
    }
    .box{
        height: 600px;
    }
    .action{
        height: 45px !important;
    }
    .bg{
        background: rgb(240,240,240);
    }
    .monitor{
        background: url('~@/assets/monitor.jpg') center;
        background-size: cover;
    }
    .score{
        background: url('~@/assets/score.jpg') center;
        background-size: cover;
    }
    .index{
        background: url('~@/assets/index.jpg') center;
        background-size: cover;
    }
    .florence-skyline{
        max-height: 200px !important;
        min-height: 150px;
        background: url('~@/assets/bg/skyline-florence.png') no-repeat center;
        background-size: cover;
        width: 100% !important;
    }
    .footer{
        width: 100%;
        bottom: 0;
        height: 55px;
    }
</style>

<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      //
    }
  }
}
</script>

<style lang="scss">
html, body{

  .row{
    margin: 0 !important;
  }

  .row + .row{
    margin-top: 0 !important;
  }

  .text-center {
    font-weight: 100;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-shadow{
    box-shadow: none !important;
  }
  .v-input__control{
    margin: auto !important;
  }
  .html .v-input--selection-controls.v-input .v-label, body .v-input--selection-controls.v-input .v-label{
    font-size: 1rem !important;
  }
  .loader{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(44,47,55);
    z-index: 999;
    img{
      width: 264px;
      height: 34px;
    }
  }
  .abs{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 300px;
    margin-top: -150px;
    margin-left: -200px;
  }
  .theme--light.application {
    background: rgb(255, 255, 255);
  }
  h1, h2{
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    font-style: italic;
  }
  h3{
    font-size: 25px;
    text-transform: uppercase;
  }
  h4{
    font-weight: 500;
    font-size: 15px;
  }
  .v-label{
    font-size: 1rem !important;
  }
  .v-input--selection-controls.v-input .v-label{
    font-size: 0.7rem !important;
    line-height: 1rem;
  }
  .ft-300{
    font-weight: 300;
  }
  .ft-400{
    font-weight: 400;
  }
  .ft-500{
    font-weight: 500;
  }
  .ft-600{
    font-weight: 600;
  }
  .ft-700{
    font-weight: 700;
  }
  .divider{
    width: 120px;
    height: 3px;
    margin: auto;
    -webkit-transition: width 2s; /* Safari */
    transition: width 2s;
  }
  .sans{
    font-family: 'Open Sans', sans-serif !important;
  }
  .garamond{
    font-family: 'Cormorant Garamond', serif !important;
  }
  .v-text-field__details{
    display: none;
  }
  @media (min-width:801px) {
    .v-input__slot {
      margin-bottom: 0 !important;
    }
  }
  .v-input__icon--clear{
    i{
      font-size: 15px !important;
    }
  }
  .v-input__slot {
    border-radius: 4px !important;
  }
  .v-select__selections{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }
  .v-select__selection{
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .v-select__slot{
    label, .v-select__selection--comma{
      font-size: 1rem;
    }
    i{
      color: white !important;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>

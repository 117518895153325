<template>
    <v-layout row wrap>
        <v-flex xs12 class="layout wrap row flex-center">
            <v-flex xs12 class="offset-md4 text-xs-center layout row align-center justify-center">
                <img :src="imagePath()" class="pr-3 pl-3" width="280px">
                <img src="@/assets/pid.png" class="pr-3 pl-3" width="120px">
            </v-flex>
            <v-flex xs12 class="layout row align-center justify-center">
                <v-btn depressed color="accent sans" @click.stop="signup = true">Accedi/Registrati</v-btn>
                <dialog-form :sign-in.sync="signIn" :sign-up.sync="signUp"></dialog-form>
            </v-flex>
        </v-flex>
        <v-flex xs12 class="text-xs-center pt-3 flex-center">
            <h1 class="garamond">
                I servizi
            </h1>
        </v-flex>
        <v-flex xs10 sm6 class="offset-sm3 offset-xs1 text-xs-center pt-5 sans text-center">
            Camera di commercio di {{title}}, con la collaborazione di Innexta, mette in contatto le imprese con il mondo del credito, dell’economia digitale e del fintech, attraverso strumenti quali piattaforme digitali, database navigabili, informazioni e approfondimenti tematici esclusivi.
        </v-flex>
        <v-flex xs10 sm6 class="offset-sm3 offset-xs1 text-xs-center pt-2 pb-5 sans text-center">
            Accedi per usufruire dei servizi riservati.
        </v-flex>
        <v-flex class="xs12 sm6 order-xs2 order-sm1 box score layout row align-end">
            <v-flex class="xs12 text-xs-right">
                <img src="@/assets/icon-score.png" width="40px" height="35" class="ma-3">
            </v-flex>
        </v-flex>
        <v-flex class="xs12 sm6 order-xs1 order-sm2 layout row align-content-center justify-center box bg wrap">
            <v-flex xs7>
                <h2 class="garamond">Innexta Score</h2>
            </v-flex>
            <v-flex xs7 class="sans">Una web-app finalizzata alla valutazione aziendale che permette agli utenti di
                conoscere lo score di bilancio di clienti, fornitori e altre imprese di interesse, a
                partire da una segnalazione «semaforica» associata ad una informativa
                sintetica ma puntuale del livello di rischiosità delle imprese ricercate.</v-flex>
            <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('https://score.innexta.it')">Vai al servizio</v-btn></v-flex>
        </v-flex>
        <v-flex class="xs12 order-xs3 order-sm3 sm6 layout row align-content-center justify-center box bg wrap">
            <v-flex xs7>
                <h2 class="garamond">Fintech Digital Index</h2>
            </v-flex>
            <v-flex xs7 class="sans">
                Un database digitale dinamico che raccoglie dati, informazioni e caratteristiche
                delle startup fintech italiane. Per ciascuna impresa fintech è disponibile una
                scheda analitica comprensiva di informazioni quali dati anagrafici e contabili,
                traguardi e obiettivi imprenditoriali, clienti di riferimento e servizi offerti.
                <br><br>
                Le funzionalità di ricerca intuitive e il collocamento delle imprese all’interno dei
                rispettivi settori fintech di appartenenza, permettono all’utente di navigare con
                facilità il mondo della finanza digitale italiana.
            </v-flex>
            <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('http://fintech.innexta.it')">Vai al servizio</v-btn></v-flex>
        </v-flex>
        <v-flex class="xs12 order-xs4 order-sm4 sm6 box index layout row align-end">
            <v-flex class="xs12 text-xs-right">
                <img src="@/assets/icon-index.png" width="40px" height="35px" class="ma-3">
            </v-flex>
        </v-flex>

        <v-flex class="xs12 order-xs8 order-sm8 sm6 layout row align-content-center justify-center box bg wrap">
            <v-flex xs7>
                <h2 class="garamond">Tool di autovalutazione per PMI innovative</h2>
            </v-flex>
            <v-flex xs7 class="sans">
                Un breve questionario per conoscere il grado di "preparazione" della tua azienda e sapere quali passi fare con l'aiuto della tua Camera di commercio per diventare una PMI innovativa.
                <br><br>
                Il tool online permette di verificare se la tua impresa ha già tutti i requisiti o se le manca qualcosa per fare il salto di qualità e accedere alle agevolazioni previste.
            </v-flex>
            <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('https://www.innexta.it/user/')">Vai al servizio</v-btn></v-flex>
        </v-flex>
        <v-flex class="xs12 order-xs7 order-sm7 sm6 box pmi layout row align-end">
            <v-flex class="xs12 text-xs-right">
                <img src="@/assets/icon-index.png" width="40px" height="35px" class="ma-3">
            </v-flex>
        </v-flex>

        <v-flex xs12 class="row black order-xs9 pa-3 layout text-xs-center justify-center">
            <span class="sans white--text">Powered by Innexta © 2022</span>
        </v-flex>
    </v-layout>
</template>

<script>

import Vue from 'vue'
import DialogForm from '@/components/form'

export default {
  components: {
    DialogForm
  },
  props: {
    title: {
      type: String,
    },
    cciaa: {
      type: String
    },
    logo: {
      type: String
    },
  },
  methods: {
    imagePath() {
      return require(`../assets/cc/${this.logo}`);
    },
    checkAuth(url) {
      if(!Vue.$cookies.get('cccf_auth')){
        this.signIn = true
      } else {
        window.open(url, '_blank');
      }
    }
  },
  data () {
    return {
      signIn: false,
      signUp: false,
    }
  }
}
</script>

<style scoped lang="scss">
    button{
        border-radius: 4px !important;
    }
    a{
        text-decoration: none;
        color: initial;
    }
    .signup{
        width: 200px;
    }
    .box{
        height: 600px;
    }
    .action{
        height: 45px !important;
    }
    .bg{
        background: rgb(240,240,240);
    }
    .monitor{
        background: url('~@/assets/monitor.jpg');
        background-size: cover;
    }
    .score{
        background: url('~@/assets/score.jpg');
        background-size: cover;
    }
    .index{
        background: url('~@/assets/index.jpg');
        background-size: cover;
    }
    .pmi{
        background: url('~@/assets/pmi.jpg');
        background-size: cover;
    }
</style>

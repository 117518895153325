<template>
    <v-layout row wrap>
        <v-flex xs12 class="layout wrap row flex-center">
            <v-flex xs12 class="offset-md4 text-xs-center layout row align-center justify-center">
                <img :src="imagePath()" class="pa-3" width="350px">
            </v-flex>
            <v-flex xs12 class="layout row align-center justify-center">
                <v-btn depressed class="accent florence-text font-weight-regular sans" @click.stop="signup = true">Accedi / Registrati</v-btn>
                <dialog-form :sign-in.sync="signIn" :sign-up.sync="signUp"></dialog-form>
            </v-flex>
        </v-flex>
        <v-layout class="row wrap align-content-start pt-5">
            <div class="layout row wrap">
                <v-flex xs12 class="text-xs-center pb-5 flex-center">
                    <h1 class="garamond font-weight-regular">Innexta Score</h1>
                </v-flex>
                <v-flex class="xs12 order-xs2 sm6 layout row align-content-center justify-center box boxbg wrap">
                    <v-flex xs7 class="sans black--text">
                        Una web-app finalizzata alla valutazione aziendale che permette agli utenti di
                        conoscere lo score di bilancio di clienti, fornitori e altre imprese di interesse, a
                        partire da una segnalazione «semaforica» associata ad una informativa
                        sintetica ma puntuale del livello di rischiosità delle imprese ricercate.
                    </v-flex>
                    <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('https://score.innexta.it')">Vai al servizio</v-btn></v-flex>
                </v-flex>
                <v-flex class="xs12 order-xs1 sm6 box score layout row align-end">
                    <v-flex class="xs12 text-xs-right">
                        <img src="@/assets/icon-score.png" width="40px" height="35px" class="ma-3">
                    </v-flex>
                </v-flex>
            </div>
        </v-layout>
        <v-flex xs12 class="footer row black order-xs7 pa-3 layout text-xs-center justify-center">
            <span class="sans white--text">Powered by Innexta © 2022</span>
        </v-flex>
    </v-layout>
</template>

<script>

import Vue from 'vue'
import DialogForm from '@/components/form'

export default {
  components: {
    DialogForm
  },
  props: {
    title: {
      type: String,
    },
    cciaa: {
      type: String
    },
    logo: {
      type: String
    },
  },
  methods: {
    imagePath() {
      return require(`../assets/cc/${this.logo}`);
    },
    checkAuth(url) {
      if(!Vue.$cookies.get('cccf_auth')){
        this.signIn = true
      } else {
        window.open(url, '_blank');
      }
    }
  },
  data () {
    return {
      signIn: false,
      signUp: false,
    }
  }
}
</script>

<style scoped lang="scss">
    button{
        border-radius: 4px !important;
    }
    a{
        text-decoration: none;
        color: initial;
    }
    .florence-text {
        background: #fff;
        color: #fff;
    }
    .florence-text-bg {
        color: #2b3876 !important;
    }
    .signup{
        width: 200px;
    }
    .box{
        height: 600px;
    }
    .boxbg{
      background: #f0f0f0;
    }
    .action{
        height: 45px !important;
    }
    .bg{
        background: rgb(240,240,240);
    }
    .monitor{
        background: url('~@/assets/monitor.jpg') center;
        background-size: cover;
    }
    .score{
        background: url('~@/assets/score.jpg') center;
        background-size: cover;
    }
    .index{
        background: url('~@/assets/index.jpg') center;
        background-size: cover;
    }
    .florence-skyline{
        max-height: 200px !important;
        min-height: 150px;
        background: url('~@/assets/bg/skyline-florence.png') no-repeat center;
        background-size: cover;
        width: 100% !important;
    }
    .footer{
        width: 100%;
        bottom: 0;
        height: 55px;
    }
</style>

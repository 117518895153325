<template>
    <v-layout class="bg column fill-height">
        <app-header></app-header>
        <v-layout class="justify-center row wrap align-content-center">
            <v-flex xs12 class="text-xs-center garamond">
                <h1>Pagina non trovata</h1>
            </v-flex>
            <v-flex xs12 class="text-xs-center sans pt-3">Il contenuto potrebbe essere invalido o scaduto</v-flex>
        </v-layout>
        <app-footer></app-footer>
    </v-layout>
</template>

<script>
    import Header from './header'
    import Footer from './footer'

    export default {
        components: {
            'app-header': Header,
            'app-footer': Footer,
        },
    }
</script>

<style scoped lang="scss">
    .bg{
        background: rgb(237,237,237);
    }
</style>
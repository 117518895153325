import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@/components/auth'
import Nuoro from '@/components/nuoro'
import Messina from '@/components/messina'
import AuthMonitor from '@/components/auth-monitor'
import AuthIndex from '@/components/auth-index'
import AuthPMI from '@/components/auth-pmi'
import NotFound from '@/components/not-found'

Vue.use(Router)


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/milano',
      props: {
        title: "Milano Monza Brianza Lodi",
        cciaa: "MILANO-MONZA-BRIANZA-LODI",
        logo: "milano.png"
      },
      component: Auth,
    },
    {
      path: '/treviso-belluno',
      props: {
        title: "Treviso Belluno",
        cciaa: "TREVISO-BELLUNO",
        logo: "treviso-belluno.png"
      },
      component: Auth,
    },
    {
      path: '/sardegna',
      props: {
        title: "Sardegna",
        cciaa: "CAGLIARI",
        logo: "sardegna.png"
      },
      component: Auth,
    },
    {
      path: '/firenze/digital-index',
      props: {
        title: "Firenze",
        cciaa: "FIRENZE",
        logo: "firenze.png"
      },
      component: AuthIndex,
    },
    {
      path: '/firenze/incentivi-monitor',
      props: {
          title: "Firenze",
          cciaa: "FIRENZE",
          logo: "firenze.png"
      },
      component: AuthMonitor,
    },
    {
      path: '/firenze/pmi',
      props: {
          title: "Firenze",
          cciaa: "FIRENZE",
          logo: "firenze.png"
      },
      component: AuthPMI,
    },
    {
      path: '/brescia',
      props: {
        title: "Brescia",
        cciaa: "BRESCIA",
        logo: "brescia.png"
      },
      component: Auth,
    },
    {
      path: '/nuoro',
      props: {
        title: "Nuoro",
        cciaa: "NUORO",
        logo: "nuoro.png"
      },
      component: Nuoro,
    },
    {
      path: '/messina',
      props: {
        title: "Messina",
        cciaa: "MESSINA",
        logo: "messina.png"
      },
      component: Messina,
    },
    {
      path: '/padova',
      props: {
        title: "Padova",
        cciaa: "PADOVA",
        logo: "padova.png"
      },
      component: Auth,
    },
    {
      path: '/genova',
      props: {
        title: "Genova",
        cciaa: "GENOVA",
        logo: "genova.png"
      },
      component: Auth,
    },
    {
      path: '/bari',
      props: {
        title: "Bari",
        cciaa: "BARI",
        logo: "bari.png"
      },
      component: Auth,
    },
    {
      path: '/bolzano',
      props: {
        title: "Bolzano",
        cciaa: "BOLZANO",
        logo: "bolzano.png"
      },
      component: Auth,
    },
    {
      path: '/laquila',
      props: {
        title: "L'Aquila",
        cciaa: "L'ACQUILA",
        logo: "laquila.png"
      },
      component: Auth,
    },
    {
      path: '/rieti',
      props: {
        title: "Rieti",
        cciaa: "RIETI",
        logo: "rieti.png"
      },
      component: Auth,
    },
    {
      path: '/palermo-enna',
      props: {
        title: "Palermo Enna",
        cciaa: "PALERMO-ENNA",
        logo: "palermo-enna.png"
      },
      component: Auth,
    },
    {
      path: '/chieti-pescara',
      props: {
        title: "Chieti Pescara",
        cciaa: "CHIETI-PESCARA",
        logo: "chieti-pescara.png"
      },
      component: Auth,
    },
    {
      path: '/venezia-giulia',
      props: {
        title: "Venezia Giulia Trieste Gorizia",
        cciaa: "VENEZIA-GIULIA",
        logo: "venezia-giulia.png"
      },
      component: Auth,
    },
    {
      path: '/riviere-liguria',
      props: {
        title: "Riviere di Liguria Imperia La Spezia Savona",
        cciaa: "RIVIERE-LIGURIA",
        logo: "riviere-liguria.png"
      },
      component: Auth,
    },
    {
      path: '/salerno',
      props: {
        title: "Salerno",
        cciaa: "SALERNO",
        logo: "salerno.png"
      },
      component: Auth,
    },
    {
      path: '/mantova',
      props: {
        title: "Mantova",
        cciaa: "MANTOVA",
        logo: "mantova.png"
      },
      component: Auth,
    },
    {
      path: '/sondrio',
      props: {
        title: "Sondrio",
        cciaa: "SONDRIO",
        logo: "sondrio.png"
      },
      component: Auth,
    },
    {
      path: '/varese',
      props: {
        title: "Varese",
        cciaa: "VARESE",
        logo: "varese.png"
      },
      component: Auth,
    },
    {
      path: '/como-lecco',
      props: {
        title: "Como Lecco",
        cciaa: "COMO",
        logo: "como-lecco.png"
      },
      component: Auth,
    },
    {
      path: '/lecco',
      props: {
        title: "Lecco",
        cciaa: "LECCO",
        logo: "lecco.png"
      },
      component: Auth,
    },
    {
      path: '/pavia',
      props: {
        title: "Pavia",
        cciaa: "PAVIA",
        logo: "pavia.png"
      },
      component: Auth,
    },
    {
      path: '/cremona',
      props: {
        title: "Cremona",
        cciaa: "CREMONA",
        logo: "cremona.png"
      },
      component: Auth,
    },
    {
      path: '/bergamo',
      props: {
        title: "Bergamo",
        cciaa: "BERGAMO",
        logo: "bergamo.png"
      },
      component: Auth,
    },
    {
      path: '/pordenone-udine',
      props: {
        title: "Pordenone Udine",
        cciaa: "PORDENONE-UDINE",
        logo: "pordenone-udine.png"
      },
      component: Auth,
    },
    {
      path: '/',
      name: 'not-found',
      component: NotFound,
      beforeEnter() {
        // Put the full page url including the protocol http(s) below
        window.location = "https://innexta.it"
      }
    }, {
      path: '*',
      name: 'err',
      redirect: '/',
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router

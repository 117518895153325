import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    theme: {
        primary: '#ac1e28',
        secondary: '#404040',
        accent: '#80b0e1',
        background: '#FFF',
    }
});

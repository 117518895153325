<template>
  <v-layout row wrap>
    <v-flex xs12 class="layout wrap row flex-center">
      <v-flex xs12 class="offset-md4 text-xs-center layout row align-center justify-center">
        <img :src="imagePath()" class="pr-3 pl-3" width="250px">
        <img src="@/assets/pid.png" class="pr-3 pl-3" width="120px">
      </v-flex>
      <v-flex xs12 class="layout row align-center justify-center">
        <v-btn depressed color="accent sans" @click="signUp = true">Accedi/Registrati</v-btn>
        <dialog-form :sign-in.sync="signIn" :sign-up.sync="signUp"></dialog-form>
      </v-flex>
    </v-flex>
    <v-flex xs12 class="text-xs-center pt-3 flex-center">
      <h1 class="garamond">
        I servizi
      </h1>
    </v-flex>
    <v-flex xs10 sm6 class="offset-sm3 offset-xs1 text-center pt-5 sans">
      Camera di commercio di {{title}}, con la collaborazione di Innexta, mette in contatto le imprese con il mondo del credito, dell’economia digitale e del fintech, attraverso strumenti quali piattaforme digitali, database navigabili, informazioni e approfondimenti tematici esclusivi.
    </v-flex>
    <v-flex xs10 sm6 class="offset-sm3 offset-xs1 text-center pt-2 pb-5 sans">
      Accedi per usufruire dei servizi riservati.
    </v-flex>
    <v-flex class="xs12 order-xs3 order-sm3 sm6 layout row align-content-center justify-center box bg wrap">
      <v-flex xs7>
        <h2 class="garamond">Equity Hub</h2>
      </v-flex>
      <v-flex xs7 class="sans">
        Tool che permette di consultare le principali caratteristiche delle imprese che hanno lanciato una campagna di equity crowdfunding o che hanno raccolto capitali utilizzando questo strumento.
      </v-flex>
      <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('https://equityhub.innexta.it')">Vai al servizio</v-btn></v-flex>
    </v-flex>
    <v-flex class="xs12 order-xs4 order-sm4 sm6 box equity layout row align-end">
      <v-flex class="xs12 text-xs-right">
        <img src="@/assets/icon-monitor.png" width="40px" height="35px" class="ma-3">
      </v-flex>
    </v-flex>
    <v-flex class="xs12 order-xs6 order-sm5 sm6 box index layout row align-end">
      <v-flex class="xs12 text-xs-right">
        <img src="@/assets/icon-index.png" width="40px" height="35px" class="ma-3">
      </v-flex>
    </v-flex>
    <v-flex class="xs12 order-xs5 order-sm6 sm6 layout row align-content-center justify-center box bg wrap">
      <v-flex xs7>
        <h2 class="garamond">Fintech Digital Index</h2>
      </v-flex>
      <v-flex xs7 class="sans">
        Il database digitale gratuito con dati e informazioni sulle principali imprese Fintech attive sul territorio italiano.
        <br><br>
        Permette di ricercare e comparare le imprese censite confrontandone i servizi, il target di mercato, gli obiettivi imprenditoriali e i principali dati di bilancio.
        <br><br>
        Un vero e proprio “ponte” tra le imprese tradizionali e gli operatori della finanza innovativa e digitale.
      </v-flex>
      <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('http://fintech.innexta.it')">Vai al servizio</v-btn></v-flex>
    </v-flex>
    <v-flex class="xs12 order-xs7 order-sm7 sm6 layout row align-content-center justify-center box bg wrap">
      <v-flex xs7>
        <h2 class="garamond">Tool di autovalutazione per PMI innovative</h2>
      </v-flex>
      <v-flex xs7 class="sans">
        Un breve questionario gratuito per conoscere il grado di "preparazione" della tua azienda e sapere quali passi fare con l'aiuto della tua Camera di commercio per diventare una PMI innovativa.
        <br><br>
        Il tool online permette di verificare se la tua impresa ha già tutti i requisiti o se le manca qualcosa per fare il salto di qualità e accedere alle agevolazioni previste.
      </v-flex>
      <v-flex xs7><v-btn outlined class="sans ma-0 mt-4 action" v-on:click="checkAuth('https://www.innexta.it/user/')">Vai al servizio</v-btn></v-flex>
    </v-flex>
    <v-flex class="xs12 order-xs8 order-sm8 sm6 box pmi layout row align-end">
      <v-flex class="xs12 text-xs-right">
        <img src="@/assets/icon-index.png" width="40px" height="35px" class="ma-3">
      </v-flex>
    </v-flex>
    <v-flex xs12 class="row black order-xs9 pa-3 layout text-xs-center justify-center">
      <span class="sans white--text">Powered by Innexta © 2022</span>
    </v-flex>
  </v-layout>
</template>

<script>

  import Vue from 'vue'
  import DialogForm from '@/components/form'

  export default {
    components: {
      DialogForm
    },
    props: {
      title: {
        type: String,
      },
      cciaa: {
        type: String
      },
      logo: {
        type: String
      },
    },
    methods: {
      imagePath() {
        return require(`../assets/cc/${this.logo}`);
      },
      checkAuth(url) {
        if(!Vue.$cookies.get('cccf_auth')){
          this.signIn = true
        } else {
          window.open(url, '_blank');
        }
      }
    },
    data () {
      return {
        signIn: false,
        signUp: false,
      }
    }
  }
</script>

<style scoped lang="scss">
 button{
   border-radius: 4px !important;
 }
 a{
   text-decoration: none;
   color: initial;
 }
 .signup{
   width: 200px;
 }
 .box{
   height: 600px;
 }
 .action{
   height: 45px !important;
 }
 .bg{
   background: rgb(240,240,240);
 }
  .equity{
    background: url('~@/assets/equity-hub.jpg');
    background-size: cover;
  }
  .score{
    background: url('~@/assets/score.jpg');
    background-size: cover;
  }
  .index{
    background: url('~@/assets/index.jpg');
    background-size: cover;
  }
  .pmi{
    background: url('~@/assets/pmi.jpg');
    background-size: cover;
  }
</style>

<template>
  <div>
    <v-dialog @input="close" :value="signUp" max-width="800px">
      <v-layout row wrap class="white pa-4">
        <v-flex xs12 class="sans justify-center layout row">
          <h3 class="ft-300"><a href="#" @click="swap">Accedi</a></h3> <h3 class="ft-300 pr-2 pl-2">/</h3><h3 class="primary--text">Registrati</h3>
        </v-flex>
        <form ref="signUpForm" class="layout row wrap xs12" @submit.prevent="signUpAction">
          <v-flex md6 xs12 class="pa-3 pb-0 text-xs-center">
            <div class="text-xs-center">Seleziona una tipologia di account</div>
            <v-radio-group v-model="radios" mandatory class="auto">
              <v-radio label="Azienda" v-bind:value="true"></v-radio>
              <v-radio label="Privato" v-bind:value="false"></v-radio>
            </v-radio-group>
            <v-flex xs12 v-if="error">
              <span>Si è verificato un errore</span>
            </v-flex>
            <v-flex xs12 class="pt-4">
              <span>Per ulteriori informazioni leggi l’<a href="https://www.innexta.it/informativa-privacy/" target="_blank" class="accent--text" rel="nofollow">Informativa sulla Privacy</a></span>
            </v-flex>
            <v-checkbox v-model="terms[0]" label="Acconsento al trattamento dei miei dati personali per l’invio di newsletter da parte del Consorzio contenenti comunicazioni istituzionali, informazioni, news e aggiornamenti relativi a eventi e attività selezionate dal Consorzio."></v-checkbox>
            <v-checkbox v-model="terms[1]" class="mt-0" label="Esprimo il consenso alla comunicazione dei miei dati personali A enti pubblici e società di natura privata per le finalità indicate nell’informativa."></v-checkbox>
            <v-checkbox v-model="terms[2]" class="mt-0" label="Esprimo il consenso al trattamento delle categorie particolari dei miei dati personali così come indicati nell’informativa che precede."></v-checkbox>
          </v-flex>
          <v-flex md6 xs12 class="pa-3 pb-0">
            <input class="sans" aria-required="true" v-model="user.companyName" placeholder="Ragione sociale" v-if="this.radios" required>
            <input class="sans" aria-required="true" v-model="user.vatCode" placeholder="Partita IVA" v-if="this.radios" required>
            <input class="sans" aria-required="true" v-model="user.firstName" placeholder="Nome" required>
            <input class="sans" aria-required="true" v-model="user.lastName" placeholder="Cognome" required>
            <input type="email" class="sans" aria-required="true" v-model="user.email" placeholder="Email" required autocomplete="email">
            <select required v-model="user.province">
              <option value="" disabled selected>Seleziona una provincia</option>
              <option v-for="item in items" :key="item">{{item}}</option>
            </select>
            <input class="sans" type="password" v-model="user.password" placeholder="Password" required autocomplete="new-password">
            <input class="sans" :class="{'err': user.password !== user.confirmation}" type="password" v-model="user.confirmation" placeholder="Conferma password" required autocomplete="new-password">
          </v-flex>
          <v-flex xs12 class="pb-0 text-xs-center flex-center">
            <v-btn type="submit" depressed class="primary signup sans mt-2" :disabled="!terms[0] || !terms[1] || !terms[2] || user.password !== user.confirmation">
              <span v-if="!loading">Registrati</span>
              <v-progress-circular indeterminate color="white" size="20" v-else width="2"></v-progress-circular>
            </v-btn>
          </v-flex>
          <v-flex xs12 v-if="error" class="text-xs-center">
            <span>Si è verificato un errore</span>
          </v-flex>
        </form>
      </v-layout>
    </v-dialog>
    <v-dialog @input="close" :value="signIn" max-width="400px">
      <v-layout row wrap class="white pa-4">
        <v-flex xs12 class="sans justify-center layout row">
          <h3 class="primary--text">Accedi</h3> <h3 class="ft-300 pr-2 pl-2">/</h3><h3 class="ft-300"><a href="#" @click="swap">Registrati</a></h3>
        </v-flex>
        <v-flex xs12 class="sans justify-center layout row" v-if="isNew">Account registrato, effettua l'accesso!</v-flex>
        <form ref="signInForm" class="layout text-xs-center row wrap pb-0 xs12" @submit.prevent="signInAction">
          <v-flex xs12 class="pt-3 pr-3 pl-3 text-xs-center">
            <input type="email" class="sans" aria-required="true" v-model="user.email" placeholder="Email" required autocomplete="email">
          </v-flex>
          <v-flex xs12 class="pt-3 pr-3 pl-3 text-xs-center">
            <input type="password" class="sans" aria-required="true" v-model="user.password" placeholder="Password" required autocomplete="current-password">
          </v-flex>
          <v-flex xs12 class="pt-3 pr-3 pl-3 text-xs-center flex-center">
            <v-btn type="submit" depressed class="primary signup sans mt-3">
              <span v-if="!loading">Conferma</span>
              <v-progress-circular indeterminate color="white" size="20" v-if="loading" width="2"></v-progress-circular>
            </v-btn>
          </v-flex>
          <v-flex xs12 v-if="error" class="text-xs-center">
            <span>Si è verificato un errore</span>
          </v-flex>
        </form>
      </v-layout>
    </v-dialog>
  </div>
</template>

<script>

import Vue from 'vue'
import Axios from "axios";

export default {
  props: {
    signIn: {
      type: Boolean,
      event: 'updateSignIn'
    },
    signUp: {
      type: Boolean,
      event: 'updateSignUp',
    },
  },
  methods: {
    close() {
      this.$emit('update:signIn', false)
      this.$emit('update:signUp', false)
    },
    swap() {
      this.$emit('update:signIn', !this.signIn)
      this.$emit('update:signUp', !this.signUp)
    },
    created: function() {
      this.items.sort();
    },
    signInAction() {
      let self = this
      self.error = false
      self.loading = true
      let bodyFormData = new FormData();
      bodyFormData.set('password', this.user.password);
      bodyFormData.set('email', this.user.email);
      setTimeout(function (){
        Axios({
          method: 'post',
          url: 'https://www.innexta.it/api/v2/login.php',
          data: bodyFormData,
          config: {headers: {'Content-Type': 'multipart/form-data'}}
        }).then((res) => {
          document.cookie = "cccf_auth="+res.data.cookie+";domain=.innexta.it;path=/;";
          self.$refs.signInForm.reset()
          self.loading = false
          self.close()
        }).catch(() => {
          self.loading = false
          self.error = true
        })
      }, 1000)
    },
    signUpAction() {
        let self = this
        self.error = false
        self.loading = true
        let bodyFormData = new FormData();
        bodyFormData.set('lastName', this.user.lastName);
        bodyFormData.set('firstName', this.user.firstName);
        bodyFormData.set('vatCode', this.user.vatCode);
        bodyFormData.set('companyName', this.user.companyName);
        bodyFormData.set('province', this.user.province);
        bodyFormData.set('password', this.user.password);
        bodyFormData.set('email', this.user.email);
        setTimeout(function () {
          Axios({
            method: 'post',
            url: 'https://www.innexta.it/api/v2/register.php',
            data: bodyFormData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
          }).then(() => {
            self.$refs.signUpForm.reset()
            self.loading = false
            self.swap()
          }).catch(() => {
            self.loading = false
            self.error = true
          })
        }, 1000)
    },
    checkAuth(url) {
      window.console.log(Vue.cookie.get('cccf_auth'))
      if(!Vue.cookie.get('cccf_auth')){
        this.signinOpen()
      } else {
        window.open(url, '_blank');
      }
    }
  },
  data() {
    return {
      error: false,
      isNew: false,
      loading: false,
      radios: true,
      user: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        companyName: null,
        vatCode: null,
        confirmation: null,
        province: "",
      },
      terms: [
          false,
          false,
          false
      ],
      items: ['AG', 'AL', 'AN', 'AO', 'AR', 'AP', 'AT', 'AV', 'BA', 'BG', 'BT', 'BL', 'BN', 'BC', 'BI', 'BO', 'BZ', 'BS', 'BR', 'CA', 'CL', 'CB', 'CI', 'CE', 'CT', 'CZ', 'CH', 'CO', 'CS','CR','KR','CN','EN','FM','FE','FI','FG','FC','FR','GE','GO','GR','IM','IS','SP','AQ','LT','LE','LC','LI','LO','LU','MC','MN','MS','MI','MT','MB','NA','NO','NU','OT','OR','PD','PA','PR','PV','PG','PU','PE','PC','PI','PT','PN','PZ','PO','RG','RA','RE','RC','RI','RN','RR','RO','SA','VS','SS','SV','SI','SR','SO','TA','TE','TR','TO','OG','TP','TV','UD','TS','VA','VE','VB','VC','VR','VV','VI','VT',],
    }
  }
}
</script>

<style scoped lang="scss">
input, select {
  width: 100%;
  height: 37px;
  border: 1px solid lightgray;
  padding: 8px 10px 8px 10px;
  border-radius: 8px;
  line-height: 1rem;
  outlined: none;
  margin-bottom: 8px;
}
.err{
  border: 1px solid indianred;
}
button{
  border-radius: 4px !important;
}
a{
  text-decoration: none;
  color: initial;
}
.signup{
  width: 200px;
}
.box{
  height: 600px;
}
.action{
  height: 45px !important;
}
</style>
